import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Spring } from "react-spring/renderprops"
import { animated, config } from "react-spring"
import Arrow from "../components/arrow"
import MediaQuery, { useMediaQuery } from "react-responsive"
// this is a test commit for remote branch
// import * as styles from "./index.module.css"
import MainLogo from "../../content/assets/ForeverEssential-PrimaryLogo.png"
import { Container } from "react-bootstrap"
import ClientOnly from "../components/clientOnly"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

export default function Home({ data }) {
  var image_bg1 = "../images/iStock-1278415959.jpg"
  var image_bg2 = "../images/iStock-1207979808.jpg"
  var image_bg3 = "../images/memorial-2.jpeg"
  var image_bg4 = "../images/clouds.png"
  var image_bg5 = "../images/memorial-4.jpeg"
  var image_bg6 = "../images/memorial-5.jpeg"
  var image_bg7 = "../images/memorial-3.jpeg"
  var image_bg8 = "../images/memorial-1.jpeg"

  var image_person1 = "../images/Brittany_Bruner_Ringo_Picture_1.jpg"
  var image_person2 = "../images/Jeff_Baumbach_Picture_1.jpg"
  var image_person3 = "../images/Nenita_Proctor.png"
  var image_person4 = "../images/Gabby_Odonnel_Picture_1.jpg"
  
  const [destination, setDestination] = useState(-1)
  const isDestination = d => {
    return d === destination
  }

  const isMobile = useMediaQuery({
    query: "(max-width: 42rem)",
  })
  const isTablet = useMediaQuery({
    query: "(max-width: 64rem)",
  })
  //highlight-line
  return (
    <div>
      <SEO
        title="Home | Forever Essential"
        description="Forever Essential is a memorial that honors the legacies of California healthcare workers who have lost their lives to COVID-19."
      />
      <ClientOnly>
        <Layout handlerDestination={setDestination}>
          {/* highlight-start */}
          <div className="section index_section">
            <div className="slide">
                  <Spring
                    delay={isDestination(0) ? 200 : 0}
                    to={{
                      opacity: isDestination(0) ? 1 : 0,
                      transform: isDestination(0)
                        ? "translateY(0)"
                        : "translateY(-150px)",
                    }}
                    config={config.molasses}
                  >
                    {props => (
                      <Container style={{ ...props, textAlign: "center" }}>
                        <h1>
                          Throughout the COVID-19 crisis, we’ve lost many brave,
                          dedicated healthcare workers.
                        </h1>
                      </Container>
                    )}
                  </Spring>
            </div>
            <div className="slide">
              <Container
                style={{
                  height: "100vh",
                  height: "-webkit-fill-available",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                    <Spring
                      delay={isDestination(0) ? 1500 : 0}
                      to={{
                        opacity: isDestination(0) ? 1 : 0,
                        transform: isDestination(0)
                          ? "translateY(0)"
                          : "translateY(-150px)",
                      }}
                      config={config.molasses}
                    >
                      {props => (
                        <div
                          style={{
                            ...props,
                            textAlign: "center",
                          }}
                        >
                          <h1>Their legacies will not be forgotten.</h1>
                          <img
                            style={{ maxWidth: "650px" }}
                            src={MainLogo}
                            alt="SEIU Memorial Main Logo"
                            width="80%"
                          ></img>
                        </div>
                      )}
                    </Spring>
                    <Spring
                      delay={isDestination(0) ? 1500 : 0}
                      to={{
                        opacity: isDestination(0) ? 1 : 0,
                        transform: isDestination(0)
                          ? "translateY(0)"
                          : "translateY(70px)",
                      }}
                      config={config.molasses}
                    >
                      {props => (
                        <div
                          style={{
                            ...props,
                            textAlign: "center",
                          }}
                        >
                          <h1>
                            <Arrow
                              direction="down"
                              width="50px"
                              onClick={e =>
                                window.fullpage_api.moveSectionDown()
                              }
                            />
                          </h1>
                        </div>
                      )}
                    </Spring>
              </Container>
            </div>
          </div>
          {/* They're Forever Essential */}
          <div
            className="section index_section"
            style={{
              background:
                "linear-gradient(180deg, rgba(255,255,255,100), rgba(0,174,184,100))",
            }}
          >
            <div className="home_jump_container">
              <Spring
                delay={0}
                to={{
                  opacity: isDestination(1) ? 1 : 1,
                }}
                config={config.molasses}
              >
                {props => (
                  <animated.div
                    className="home_jump"
                    style={{
                      ...props,
                    }}
                  >
                    <Spring
                      delay={isDestination(1) ? 2000 : 750}
                      to={{
                        opacity: isDestination(1) ? 1 : 0,
                        transform: isDestination(1)
                          ? "translateY(0)"
                          : "translateY(0)",
                      }}
                      config={config.molasses}
                    >
                      {props => (
                        <animated.div
                          className="home_jump_header"
                          style={{
                            ...props,
                            alignSelf: "center",
                          }}
                        >
                          <h1 className="home_jump_header_text">
                            They’re forever essential.
                          </h1>
                        </animated.div>
                      )}
                    </Spring>
                    <div className="home_jump_subcontainer">
                      <Spring
                        delay={isDestination(1) ? 2500 : 0}
                        to={{
                          opacity: isDestination(1) ? 1 : 0,
                          transform: isDestination(1)
                            ? "translateY(0)"
                            : "translateY(150px)",
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_subheader"
                            style={props}
                          >
                            <p
                              style={
                                isMobile
                                  ? {
                                      width: "80vw",
                                      marginTop: "20%",
                                      transform: "translateX(-20%)",
                                    }
                                  : { marginTop: "2%" }
                              }
                              className="home_jump_subheader_text"
                            >
                              Forever Essential honors the legacies of
                              California healthcare workers who we’ve lost to
                              COVID-19.
                            </p>
                          </animated.div>
                        )}
                      </Spring>
                      <Spring
                        delay={isDestination(1) ? 1500 : 0}
                        to={{
                          opacity: isDestination(1) ? 1 : 0,
                          transform: isDestination(1)
                            ? "translateY(0)"
                            : "translateY(650px)",
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_worker"
                            style={{
                              ...props,
                              marginBottom: isMobile ? "5%" : "25%",
                              marginLeft: "20%",
                            }}
                          >
                            <StaticImage src={image_person1}
                              imgClassName="home_jump_worker_image"
                              placeholder="blurred"
                              alt="Brittany Bruner Ringo" />
                          </animated.div>
                        )}
                      </Spring>
                    </div>
                    <Spring
                      delay={isDestination(1) ? 2000 : 0}
                      to={{
                        opacity: isDestination(1) ? 1 : 0,
                        transform: isDestination(1)
                          ? "translateY(0)"
                          : "translateY(-500px)",
                      }}
                      config={config.molasses}
                    >
                      {props => (
                        <animated.div
                          style={props}
                          className="home_jump_bg_flair_l"
                        >
                          <StaticImage src={image_bg1}
                            layout="fixed"
                            placeholder="blurred"
                            alt="Meadow" />
                        </animated.div>
                      )}
                    </Spring>
                    <Spring
                      delay={isDestination(1) ? 2000 : 0}
                      to={{
                        opacity: isDestination(1) ? 1 : 0,
                        transform: isDestination(1)
                          ? "translateY(0)"
                          : "translateY(-300px)",
                      }}
                      config={config.molasses}
                    >
                      {props => (
                        <animated.div
                          style={props}
                          className="home_jump_bg_flair_r"
                        >
                          <StaticImage src={image_bg1}
                            layout="fixed"
                            placeholder="blurred"
                            alt="Meadow" />
                        </animated.div>
                      )}
                    </Spring>
                  </animated.div>
                )}
              </Spring>
            </div>
          </div>
          {/* Their Stories */}
          <div
            className="section index_section"
            style={{
              background:
                "linear-gradient(180deg, rgba(255,255,255,100), rgba(0,174,184,100))",
            }}
          >
            <div className="home_jump_container">
              <Spring
                delay={0}
                to={{
                  opacity: isDestination(2) ? 1 : 1,
                }}
                config={config.molasses}
              >
                {props => (
                  <animated.div
                    className="home_jump"
                    style={{
                      ...props,
                      position: "relative",
                    }}
                  >
                    <Spring
                      delay={isDestination(2) ? 2000 : 750}
                      to={{
                        opacity: isDestination(2) ? 1 : 0,
                        transform: isDestination(2)
                          ? "translateY(0)"
                          : "translateY(0)",
                      }}
                      config={config.molasses}
                    >
                      {props => (
                        <animated.div
                          className="home_jump_header"
                          style={{
                            ...props,
                            alignSelf: isMobile ? "center" : "flex-start",
                            width: isMobile ? "90%" : "70%",
                          }}
                        >
                          <h1
                            className="home_jump_header_text"
                            style={{
                              marginTop: isMobile
                                ? "20%"
                                : isTablet
                                ? "60%"
                                : "2%",
                              padding: "0px",
                              textAlign: isMobile ? "center" : "left",
                            }}
                          >
                            Their Stories
                          </h1>
                          <Spring
                            delay={isDestination(2) ? 2500 : 0}
                            to={{
                              opacity: isDestination(2) ? 1 : 0,
                              transform: isDestination(2)
                                ? "translateY(0)"
                                : "translateY(150px)",
                            }}
                            config={config.molasses}
                          >
                            {props => (
                              <animated.div
                                className="home_jump_subheader"
                                style={{
                                  ...props,
                                  justifyContent: "flex-start",
                                }}
                              >
                                <p
                                  className="home_jump_subheader_text"
                                  style={{ width: isTablet ? "100%" : "60%" }}
                                >
                                  Together, we’ve lost coworkers, family
                                  members, good neighbors, and best friends. May
                                  their courage, compassion, and commitment
                                  continue to shine through us.
                                </p>
                              </animated.div>
                            )}
                          </Spring>
                        </animated.div>
                      )}
                    </Spring>
                    <MediaQuery maxWidth="42rem">
                      <Spring
                        delay={55}
                        to={{
                          opacity: isDestination(2) ? 1 : 0,
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <div
                            style={{
                              ...props,
                              alignSelf: "flex-end",
                              position: "relative",
                              zIndex: "20",
                              bottom: "15%",
                              left: "-40%",
                            }}
                          >
                            <h1>
                              <Arrow
                                direction="down"
                                width="26%"
                                height="100%"
                                black="true"
                                onClick={e =>
                                  window.fullpage_api.moveSectionDown()
                                }
                              />
                            </h1>
                          </div>
                        )}
                      </Spring>
                    </MediaQuery>
                    <Spring
                      delay={isDestination(2) ? 2500 : 0}
                      to={{
                        opacity: isDestination(2) ? 1 : 0,
                        transform: isDestination(2)
                          ? "translateY(0)"
                          : "translateY(-200px)",
                      }}
                      config={config.molasses}
                    >
                      {props => (
                        <animated.div
                          className="home_jump_link"
                          style={{
                            ...props,
                            flexDirection: "column",
                            position: "absolute",
                            zIndex: "20",
                            left: "55%",
                            bottom: isMobile ? "20%" : "none",
                          }}
                        >
                          <a
                            className="home_jump_link_text"
                            href="/their-stories"
                          >
                            See more stories
                          </a>
                          <a
                            className="home_jump_link_arrow"
                            href="/their-stories"
                          >
                            <Arrow width="10px" direction="right" />
                          </a>
                        </animated.div>
                      )}
                    </Spring>
                    <div
                      className="home_jump_subcontainer"
                      style={{
                        flexDirection: "row",
                        position: "absolute",
                        marginLeft: "15%",
                        transform: "translateX(0%)",
                        width: "85%",
                      }}
                    >
                      <Spring
                        delay={isDestination(2) ? 1500 : 0}
                        to={{
                          opacity: isDestination(2) ? 1 : 0,
                          transform: isDestination(2)
                            ? "translateY(0%)"
                            : "translateY(450px)",
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_worker"
                            style={{
                              ...props,
                              alignSelf: "flex-end",
                              marginBottom: isMobile
                              ? "-25%"
                              : "-14%",
                              marginLeft: isMobile
                                ? "-20%"
                                : "5%"
                            }}
                          >
                            <StaticImage src={image_person2}
                              imgClassName="home_jump_worker_image"
                              placeholder="blurred"
                              alt="Jeff Baumbach" />
                          </animated.div>
                        )}
                      </Spring>
                      <Spring
                        delay={isDestination(2) ? 1500 : 0}
                        to={{
                          opacity: isDestination(2) ? 1 : 0,
                          transform: isDestination(2)
                            ? "translateY(0)"
                            : "translateY(-450px)",
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_worker"
                            style={{ 
                              ...props,
                              alignSelf: "flex-start",
                              marginTop: isMobile ? "-2%" : "0%"
                            }}
                          >
                            <StaticImage src={image_person3}
                              imgClassName="home_jump_worker_image"
                              placeholder="blurred"
                              alt="Nenita Proctor" />
                          </animated.div>
                        )}
                      </Spring>
                      <Spring
                        delay={isDestination(2) ? 1500 : 0}
                        to={{
                          opacity: isDestination(2) ? 1 : 0,
                          transform: isDestination(2)
                            ? "translateY(0)"
                            : "translateY(450px)",
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_worker"
                            style={{
                              ...props,
                              alignSelf: "flex-end",
                              marginBottom: "3%",
                              marginRight: "2%",
                              display: isMobile
                                ? "none"
                                : "flex"
                            }}
                          >
                            <StaticImage src={image_person4}
                              imgClassName="home_jump_worker_image"
                              placeholder="blurred"
                              alt="Gabby Odonnel" />
                          </animated.div>
                        )}
                      </Spring>
                    </div>
                  </animated.div>
                )}
              </Spring>
            </div>
          </div>
          {/* Add to the Memorial */}
          <div
            className="section index_section"
            style={{
              background:
                "linear-gradient(180deg, rgba(255,255,255,100), rgba(0,174,184,100))",
            }}
          >
            <div className="home_jump_container">
              <Spring
                delay={0}
                to={{
                  opacity: isDestination(3) ? 1 : 1,
                }}
                config={config.molasses}
              >
                {props => (
                  <animated.div
                    className="home_jump"
                    style={{
                      ...props,
                      position: "relative",
                      padding: "0",
                    }}
                  >
                    <Spring
                      delay={isDestination(3) ? 2000 : 750}
                      to={{
                        opacity: isDestination(3) ? 1 : 0,
                        transform: isDestination(3)
                          ? "translateY(0)"
                          : "translateY(0)",
                      }}
                      config={config.molasses}
                    >
                      {props => (
                        <animated.div
                          className="home_jump_header"
                          style={{
                            ...props,
                            alignSelf: "center",
                            width: "90%",
                          }}
                        >
                          <h1
                            className="home_jump_header_text"
                            style={{
                              marginTop: "2%",
                              padding: "0px",
                              whiteSpace: "pre-wrap",
                              textAlign: isMobile ? "center" : "left",
                            }}
                          >
                            Add{isMobile ? "\n" : " "}to{isMobile ? " " : "\n"}
                            the Memorial
                          </h1>
                          <Spring
                            delay={isDestination(3) ? 2500 : 0}
                            to={{
                              opacity: isDestination(3) ? 1 : 0,
                              transform: isDestination(3)
                                ? "translateY(0)"
                                : "translateY(150px)",
                            }}
                            config={config.molasses}
                          >
                            {props => (
                              <animated.div
                                className="home_jump_subheader"
                                style={{
                                  ...props,
                                  justifyContent: "flex-end",
                                }}
                              >
                                <p
                                  className="home_jump_subheader_text"
                                  style={{
                                    marginRight: "15%",
                                    width: isTablet ? "100vw" : "35vw",
                                  }}
                                >
                                  Have you lost a healthcare worker in your life
                                  to COVID-19? Honor their memory and share
                                  their legacy.
                                </p>
                              </animated.div>
                            )}
                          </Spring>
                        </animated.div>
                      )}
                    </Spring>
                    <MediaQuery maxWidth="42rem">
                      <Spring
                        delay={55}
                        to={{
                          opacity: isDestination(3) ? 1 : 0,
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <div
                            style={{
                              ...props,
                              alignSelf: "flex-end",
                              position: "relative",
                              zIndex: "20",
                              bottom: "15%",
                              left: "-40%",
                            }}
                          >
                            <h1>
                              <Arrow
                                direction="down"
                                width="33%"
                                height="100%"
                                black="true"
                                onClick={e =>
                                  window.fullpage_api.moveSectionDown()
                                }
                              />
                            </h1>
                          </div>
                        )}
                      </Spring>
                    </MediaQuery>
                    <Spring
                      delay={isDestination(3) ? 2500 : 0}
                      to={{
                        opacity: isDestination(3) ? 1 : 0,
                        transform: isDestination(3)
                          ? "translateY(0)"
                          : "translateY(-200px)",
                      }}
                      config={config.molasses}
                    >
                      {props => (
                        <animated.div
                          className="home_jump_link"
                          style={{
                            ...props,
                            flexDirection: "column",
                            position: "absolute",
                            zIndex: "20",
                            left: isMobile ? "60%" : "75%",
                            bottom: isMobile ? "25%" : "10%",
                          }}
                        >
                          <a
                            className="home_jump_link_text"
                            href="/their-stories/#4"
                            style={{ color: "black" }}
                          >
                            Share a Story
                          </a>
                          <a
                            className="home_jump_link_arrow"
                            href="/their-stories/#4"
                          >
                            <Arrow width="10px" direction="right" />
                          </a>
                        </animated.div>
                      )}
                    </Spring>
                    <div
                      className="home_jump_subcontainer"
                      style={{
                        flexDirection: "row",
                        position: "absolute",
                        marginLeft: "0",
                        transform: "translateX(0%)",
                        width: "100%",
                      }}
                    >
                      <Spring
                        delay={isDestination(3) ? 2000 : 0}
                        to={{
                          opacity: isDestination(3) ? 1 : 0,
                          transform: isDestination(3)
                            ? "translateY(0) scale(2,2) translateX(-60%)"
                            : "translateY(500px) scale(2,2) translateX(-60%)",
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_bg"
                            style={{
                              ...props,
                              alignSelf: "flex-end",
                              marginBottom: "25%",
                              display: isMobile
                                ? "none"
                                : "flex",
                            }}
                          >
                            <StaticImage src={image_bg3}
                              imgClassName="home_jump_bg_image"
                              placeholder="blurred"
                              alt="Landscape" />
                          </animated.div>
                        )}
                      </Spring>
                      <Spring
                        delay={isDestination(3) ? 2000 : 0}
                        to={{
                          opacity: isDestination(3) ? 1 : 0,
                          transform: isDestination(3)
                          ? (isMobile ? "translateY(0)" : "translateY(0)")
                          : (isMobile ? "translateY(250px)" : "translateY(-250px)"),
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_bg"
                            style={{
                              ...props,
                              alignSelf: isMobile ? "flex-end" : "auto",
                              justifyContent: "flex-end",
                            }}
                          >
                            <StaticImage src={image_bg8}
                              imgClassName="home_jump_bg_image"
                              placeholder="blurred"
                              imgStyle={{
                                overflow: "hidden",
                                justifyContent: "flex-end"
                              }}
                              alt="Abstract" />
                          </animated.div>
                        )}
                      </Spring>
                      <Spring
                        delay={isDestination(3) ? 2000 : 0}
                        to={{
                          opacity: isDestination(3) ? 1 : 0,
                          transform: isDestination(3)
                            ? (isMobile ? "translateY(0) scale(1,1)" : "translateY(0) scale(1.5,1.5)")
                            : (isMobile ? "translateY(-250px) scale(1,1)" : "translateY(250px) scale(1.5,1.5)"),
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_bg"
                            style={{
                              ...props,
                              height: "25%",
                              display: "flex",
                              flexDirection: "column",
                              marginBottom:"-10%",
                              alignSelf: isMobile ? "flex-start" : "flex-end",
                              justifyContent: isMobile ? "flex-start" : "flex-end",
                            }}
                          >
                            <StaticImage src={image_bg4}
                              imgClassName="home_jump_bg_image"
                              placeholder="blurred"
                              alt="Meadow" />
                          </animated.div>
                        )}
                      </Spring>
                      <Spring
                        delay={isDestination(3) ? 2000 : 0}
                        to={{
                          opacity: isDestination(3) ? 1 : 0,
                          transform: isDestination(3)
                          ? "translateY(0) translateX(60%)"
                          : "translateY(-550px) translateX(60%)",
                      }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_bg"
                            style={{
                              ...props,
                              alignSelf: "flex-end",
                              marginBottom: "10%",
                              display: isMobile
                                ? "none"
                                : "flex",
                            }}
                          >
                            <StaticImage src={image_bg7}
                              imgClassName="home_jump_bg_image"
                              placeholder="blurred"
                              alt="Meadow" />
                          </animated.div>
                        )}
                      </Spring>
                    </div>
                  </animated.div>
                )}
              </Spring>
            </div>
          </div>
          {/* Justice & Unity */}
          <div
            className="section index_section"
            style={{
              background:
                "linear-gradient(180deg, rgba(255,255,255,100), rgba(0,174,184,100))",
            }}
          >
            <div className="home_jump_container">
              <Spring
                delay={0}
                to={{
                  opacity: isDestination(4) ? 1 : 1,
                }}
                config={config.molasses}
              >
                {props => (
                  <animated.div
                    className="home_jump"
                    style={{
                      ...props,
                      position: "relative",
                    }}
                  >
                    <Spring
                      delay={isDestination(4) ? 2000 : 750}
                      to={{
                        opacity: isDestination(4) ? 1 : 0,
                        transform: isDestination(4)
                          ? "translateY(0)"
                          : "translateY(0)",
                      }}
                      config={config.molasses}
                    >
                      {props => (
                        <animated.div
                          className="home_jump_header"
                          style={{
                            ...props,
                            alignSelf: "flex-start",
                            width: isTablet ? "80%" : "55%",
                          }}
                        >
                          <h1
                            className="home_jump_header_text"
                            style={{
                              textAlign: isMobile ? "center" : "left",
                              color: "white",
                              marginTop: isTablet ? "13%" : "5px",
                              padding: "0px",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            Justice &amp; Unity
                          </h1>
                          <Spring
                            delay={isDestination(4) ? 2500 : 0}
                            to={{
                              opacity: isDestination(4) ? 1 : 0,
                              transform: isDestination(4)
                                ? "translateY(0)"
                                : "translateY(150px)",
                            }}
                            config={config.molasses}
                          >
                            {props => (
                              <animated.div
                                className="home_jump_subheader"
                                style={{
                                  ...props,
                                  justifyContent: "flex-start",
                                }}
                              >
                                <p
                                  className="home_jump_subheader_text"
                                  style={{
                                    width: '100%',
                                    whiteSpace: "pre-wrap",
                                    paddingTop: "2%",
                                  }}
                                >
                                  Forever Essential, a Healthcare Worker
                                  Memorial, is proudly sponsored by SEIU United
                                  Healthcare Workers West (SEIU-UHW), a
                                  healthcare justice union of more than 100,000
                                  frontline healthcare workers, patients, and
                                  consumers.
                                  {"\n\n"}
                                  As essential healthcare workers, every day we
                                  bear witness to the devastating toll of the
                                  COVID-19 pandemic – on our patients, our
                                  families, and our co-workers. Through this
                                  memorial, we hope to transform our private
                                  pain into public dignity and to carry forward
                                  the legacies of those we’ve lost.
                                </p>
                              </animated.div>
                            )}
                          </Spring>
                          <MediaQuery maxWidth="42rem">
                            <Spring
                              delay={550}
                              to={{
                                opacity: isDestination(4) ? 1 : 0,
                                transform: isDestination(4)
                                  ? "translateY(0)"
                                  : "translateY(70px)",
                              }}
                              config={config.molasses}
                            >
                              {
                                (props,
                                black => (
                                  <div
                                    style={{
                                      ...props,
                                      textAlign: "center",
                                      marginTop: isMobile ? "-10%" : "5%",
                                      src: "black",
                                    }}
                                  >
                                    <h1>
                                      <Arrow
                                        direction="down"
                                        width="4%"
                                        black="true"
                                        onClick={e =>
                                          window.fullpage_api.moveSectionDown()
                                        }
                                      />
                                    </h1>
                                  </div>
                                ))
                              }
                            </Spring>
                          </MediaQuery>
                        </animated.div>
                      )}
                    </Spring>
                    <div
                      className="home_jump_subcontainer"
                      style={{
                        flexDirection: "row",
                        position: "absolute",
                        marginLeft: "0",
                        transform: "translateX(0%)",
                        width: "100%",
                      }}
                    >
                      <Spring
                        delay={isDestination(4) ? 2000 : 0}
                        to={{
                          opacity: isDestination(4) ? 1 : 0,
                          transform: isDestination(4)
                          ? isMobile ? "translateY(0) scale(1,1) translateX(0)" : "translateY(0) scale(2,2) translateX(-65%)" 
                          : isMobile ? "translateY(-250px) scale(1,1) translateX(0)" : "translateY(300px) scale(2,2) translateX(-65%)",
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_bg"
                            style={{
                              ...props,
                              marginBottom: "5%",
                              alignSelf: isMobile ? "flex-start" : "flex-end",
                              justifyContent: isMobile ? "flex-start" : "flex-end",
                            }}
                          >
                            <StaticImage src={image_bg2}
                              imgClassName="home_jump_bg_image"
                              placeholder="blurred"
                              alt="Abstract" />
                          </animated.div>
                        )}
                      </Spring>
                      <Spring
                        delay={isDestination(4) ? 2000 : 0}
                        to={{
                          opacity: isDestination(4) ? 1 : 0,
                          transform: isDestination(4)
                          ? isMobile ? "translateY(0) scale(1,1) translateX(0)" : "translateY(0) scale(1.2, 1.2) translateY(20%)" 
                          : isMobile ? "translateY(250px) scale(1,1) translateX(0)" : "translateY(-300px) scale(1.2, 1.2) translateY(20%)",
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_bg"
                            style={{ ...props,
                              alignSelf: isMobile ? "flex-end" : "flex-start",
                              justifyContent: isMobile ? "flex-end" : "flex-end",
                              paddingRight: isMobile ? "0" : "5%",
                            }}
                          >
                            <StaticImage src={image_bg4}
                              imgClassName="home_jump_bg_image"
                              placeholder="blurred"
                              alt="Sky" />
                          </animated.div>
                        )}
                      </Spring>
                      <Spring
                        delay={isDestination(4) ? 2000 : 0}
                        to={{
                          opacity: isDestination(4) ? 1 : 0,
                          transform: isDestination(4)
                            ? "translateY(0px) scale(1.3,1.3) translateX(25%)"
                            : "translateY(500px) scale(1.3,1.3) translateX(25%)",
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_bg"
                            style={{
                              ...props,
                              height: "100%",
                              marginTop: "-20%",
                              flexDirection: "column",
                              justifyContent: "flex-end",
                              zIndex: 100,
                              display: isMobile
                                ? "none"
                                : "flex",
                            }}
                          >
                            {/* <StaticImage src={image_bg5}
                              imgClassName="home_jump_bg_image"
                              placeholder="blurred"
                              alt="Trees" /> */}
                              <div
                                className="video"
                              >
                                <Helmet>
                                  <script
                                    src="https://fast.wistia.com/embed/medias/2ofmt0st0u.jsonp"
                                    async
                                  ></script>
                                  <script
                                    src="https://fast.wistia.com/assets/external/E-v1.js"
                                    async
                                  ></script>
                                </Helmet>
                                <div
                                  className="wistia_responsive_padding"
                                  style={{
                                    padding: "61.04% 0 0 0",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    className="wistia_responsive_wrapper"
                                    style={{
                                      height: "100%",
                                      left: 0,
                                      position: "absolute",
                                      top: 0,
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      className="wistia_embed wistia_async_2ofmt0st0u videoFoam=true"
                                      style={{
                                        height: "391px",
                                        position: "relative",
                                        width: "640px",
                                      }}
                                    >
                                      <div
                                        className="wistia_swatch"
                                        style={{
                                          height: "100%",
                                          left: 0,
                                          opacity: 0,
                                          overflow: "hidden",
                                          position: "absolute",
                                          top: 0,
                                          transition: "opacity 200ms",
                                          width: "100%",
                                        }}
                                      >
                                        <img
                                          src="https://fast.wistia.com/embed/medias/phios68bsd/swatch"
                                          style={{
                                            filter: "blur(5px)",
                                            height: "100%",
                                            objectFit: "contain",
                                            width: "100%",
                                          }}
                                          alt=""
                                          aria-hidden="true"
                                          onload="this.parentNode.style.opacity=1;"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </animated.div>
                        )}
                      </Spring>
                      <Spring
                        delay={isDestination(4) ? 2000 : 0}
                        to={{
                          opacity: isDestination(4) ? 1 : 0,
                          transform: isDestination(4)
                            ? "translateY(0) translateX(60%)"
                            : "translateY(-550px) translateX(60%)",
                        }}
                        config={config.molasses}
                      >
                        {props => (
                          <animated.div
                            className="home_jump_bg"
                            style={{
                              ...props,
                              alignSelf: "flex-end",
                              marginBottom: "30%",
                              display: isMobile
                                ? "none"
                                : "flex",
                            }}
                          >
                            <StaticImage src={image_bg6}
                              imgClassName="home_jump_bg_image"
                              placeholder="blurred"
                              alt="Dock" />
                          </animated.div>
                        )}
                      </Spring>
                    </div>
                  </animated.div>
                )}
              </Spring>
            </div>
          </div>
        </Layout>
      </ClientOnly>
    </div>
  )
}

//highlight-start
export const pageQuery = graphql`
  query {
    allWpPage(sort: { fields: [date] }) {
      nodes {
        title
        slug
      }
    }
  }
`
//highlight-end
